import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Having processed nearly 3 billion messages, we’ve taken an updated look at the most popular, and sometimes not so popular, messages users send to Facebook bots.`}</p>
    <p>{`“Hi” and “Hello” are still the most popular messages. Each month, more than 60% of bots receive the message “hi.” In April, it was 60.2%, March 61.5%, February 67.4%, and January 65.3%.`}</p>
    <p>{`Despite “hi” being a common message, not all bots support it. We had a meet up recently where we entered “hi” into one of the demo bots and the response was, “Sorry, not sure what you mean.”`}</p>
    <p>{`Adding support for these common messages will make your bot more engaging and increase the overall usage of your bot.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/05/07130006/0_odWy6Hh5EKYN8b8-.png)
    </figure>
Just as important as the most common messages, there are other key messages
developers should consider implementing support for including “help” and “stop,”
for example.
    <p>{`Chatbots are still a relatively new medium, some users don’t know how to use the bot or what a particular bot does. A response to this message can be… helpful…`}</p>
    <p>{`“Stop” is another important message to consider supporting, especially if your bot sends a lot of broadcast messages. On average, more than 21% of Facebook bots receive this message each month.`}</p>
    <p>{`Adding support for “stop” can be the difference between a blocked user and a retained one. One of our customers broadcasts sports scores and found users would block their bot when their teams were doing poorly. They would then have to pay advertising costs to reacquire these users. They added a “mute” functionality, similar to stop, that enabled users to temporarily block the messages without actually blocking the bot.`}</p>
    <p>{`Well, there comes a time when even a bot has a rough day.`}</p>
    <p>{`One of the interesting aspects of conversational interfaces is that not only will users tell the bot what they want from it, they will tell it what they think of it afterwards, which sometimes isn’t too pleasant.`}</p>
    <p>{`It turns out, on average about 14% of Facebook bots receive the message “f`}{`*`}{`*`}{`*`}{` you” each month.`}</p>
    <p>{`This could be an opportunity to respond with a fun animated gif, a picture of a cute cat, or perhaps a bar of soap.`}</p>
    <p>{`What trumps bots? Out of curiosity, we took a look at how many bots receive messages about Donald Trump, which turned out to be about 11.7% of Facebook bots.`}</p>
    <p>{`Interestingly, Trump trumps both Obama and Clinton in the percentage of bots and even more so in the amount of messages. Trump appears in 11.7% of bots, Obama 7.3%, and Clinton 4.8%. When it comes to the number of messages though, Trump is mentioned in more than 4.8 times the number of messages Obama is mentioned in, and 7.5 times the amount for Clinton.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/05/07130006/0_CLgaNzcBd-x_MHzK.png)
    </figure>
We looked at the same messages for Slack bots. In about 21.5% of Slack bots,
users mention Trump, compared to 13.7% for Obama, and 9.9% for Clinton. The
message count difference is similar to Facebook — there are 5.7 times more
messages containing Trump than Obama; and 8.4 times more Trump messages than
Clinton.
    <p>{`Perhaps `}<em parentName="p">{`“someone”`}</em>{` should tweet about this…`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/05/07130005/0_gJ1xDPYNYzzuaiUs.png)
    </figure>
To end on a more fun note, let’s look at the most common stickers so far this
year.
    <p>{`As we’ve mentioned before, it’s important to look at all the different types of messages users are sending. These aren’t just images — they convey feelings and emotions users are communicating to your bot. By adding support for these, you can create a personality for your bot and increase user engagement.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/05/07130004/0_XfAK2oSbsDcjvRZl-666x1024.png)
    </figure>
[Dashbot](http://www.dashbot.io/) is a bot analytics platform that enables
developers and brands to increase engagement, acquisition, and monetization
through actionable data and tools.
    <p>{`In addition to traditional analytics like engagement and retention, we provide bot specific metrics including sentiment analysis, conversational analytics, and the full chat session transcripts.`}</p>
    <p>{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</p>
    <p>{`We natively support Facebook Messenger, Alexa, Google Home, Slack, and Kik and have a generic API for any conversational interface.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      